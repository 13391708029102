import { policyList } from "@/enums/permissions";
import { InventoryBatch } from "@/interfaces/batch";
import { SearchQuery } from "@/interfaces/httpQuery";
import { Product } from "@/interfaces/product";
import { store } from "@/internal";
import HttpService from "@/services/http.service";
import { TableAction } from "helix-vue-components";

class BrandSubTableService extends HttpService {
  protected batchQuery = "q[in_store_quantity_value_gt]";
  protected uri: string = "catalog/brands";
  protected defaultSort: string = "";
  protected loadDispatcher: string = "BrandModule/loadBatches";
  protected searchableField: SearchQuery[] = [
    {
      field: "product.name",
      type: "contains"
    }
  ];
  constructor() {
    super();
    this.query.embed = "product";
  }

  public async getProductsAssigned(brandId: string): Promise<Product[]> {
    this.uri = "catalog/products";
    this.query["q[brand_id_equals]"] = brandId;
    const embedCopy = this.query.embed;
    this.query.embed = "batchType,category";
    const response = await super.get(this.query);
    this.uri = "catalog/brands";
    delete this.query["q[brand_id_equals]"];
    this.query.embed = embedCopy;
    return response.data.data;
  }

  public async getBatchesAssigned(
    brandId: string,
    zeroOnly: boolean | undefined
  ): Promise<InventoryBatch[]> {
    try {
      this.uri = `catalog/brands/${brandId}/batches`;
      const query = this.query;
      delete query["q[in_store_quantity_value_eq]"];
      delete query["q[in_store_quantity_value_gt]"];
      if (typeof zeroOnly !== "undefined") {
        zeroOnly
          ? (this.batchQuery = "q[in_store_quantity_value_eq]")
          : (this.batchQuery = "q[in_store_quantity_value_gt]");
        query.page = 1;
      }
      query[this.batchQuery] = 0;
      const response = await super.get(query);
      this.uri = "brands";
      return response.data.data;
    } catch (e) {
      return [];
    }
  }

  public getBatchesGeneralActions(): TableAction[] {
    return [
      {
        icon: "fal fa-search",
        otherComponent: {
          name: "TableSearchComponent",
          action: this.searchEvent()
        }
      }
    ];
  }

  public getProductsRowActions(): TableAction[] {
    return [
      {
        icon: "fal fa-pen",
        action: (product: Product) => {
          store.dispatch("RouterModule/go", {
            name: "products-edit",
            params: {
              id: product.sku,
              currentModel: product
            }
          });
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.modifyBrands
          )
      }
    ];
  }

  public getBatchesRowActions(): TableAction[] {
    return [
      {
        icon: "fal fa-pen",
        action: (batch: InventoryBatch) => {
          store.dispatch("RouterModule/go", {
            name: "batch-details",
            params: {
              id: batch.batch_uid,
              currentModel: batch
            }
          });
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.modifyBrands
          )
      }
    ];
  }
}

export const brandSubTableService: BrandSubTableService = new BrandSubTableService();
