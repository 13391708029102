import { Batch } from "@/interfaces/batch";
import { Brand } from "@/interfaces/brand";
import { Product } from "@/interfaces/product";
import { TablePagination } from "helix-vue-components";
import { MutationTree } from "vuex";
import { BrandState } from "./brand.type";

type BrandMutationTree = MutationTree<BrandState>;

export const mutations: BrandMutationTree = {
  setBrands(state: BrandState, brands: Brand[]) {
    state.brands = brands;
  },
  setLoading(state: BrandState, loading: boolean) {
    state.loading = loading;
  },
  setPagination(state: BrandState, pagination: TablePagination | null) {
    state.pagination = pagination;
  },
  setBrand(state: BrandState, brand: Brand | null) {
    state.brand = brand;
  },
  setProducts(state: BrandState, products: Product[] | null) {
    state.products = products;
  },
  setBatches(state: BrandState, batches: Batch[] | null) {
    state.batches = batches;
  },
  setProductsPagination(state: BrandState, pagination: TablePagination | null) {
    state.productsPagination = pagination;
  },
  setBatchesPagination(state: BrandState, pagination: TablePagination | null) {
    state.batchesPagination = pagination;
  }
};
