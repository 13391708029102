import { RootState } from "@/vuex/types";
import { Module } from "vuex";
import { actions } from "./brand.actions";
import { getters } from "./brand.getters";
import { mutations } from "./brand.mutations";
import { state } from "./brand.state";
import { BrandState } from "./brand.type";

const namespaced: boolean = true;
export const BrandModule: Module<BrandState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
