import { InventoryBatch } from "@/interfaces/batch";
import { Brand } from "@/interfaces/brand";
import { Vendor } from "@/interfaces/vendor";
import { store } from "@/internal";
import { brandService } from "@/services/brand.service";
import { brandSubTableService } from "@/services/brandSubTables.service";
import { messagesService } from "@/services/messages.service";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { ActionContext, ActionTree } from "vuex";
import { BrandState } from "./brand.type";

type BrandActionContext = ActionContext<BrandState, RootState>;
type BrandActionTree = ActionTree<BrandState, RootState>;

export const actions: BrandActionTree = {
  async loadBrands(context: BrandActionContext): Promise<any> {
    try {
      context.commit("setLoading", true);
      const brands = await brandService.get();
      context.commit("setBrands", brands);
      const pagination: TablePagination = await brandService.getPagination();
      context.commit("setPagination", pagination);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async enableMultiple(
    context: BrandActionContext,
    brands: Brand[]
  ): Promise<any> {
    const brandIds = brands.map((brand: Brand) => brand.id!);
    context.commit("setLoading", true);
    try {
      await brandService.enableMultiple(brandIds);
      messagesService.renderSuccessMessage("updated_successfully");
      context.dispatch("loadBrands");
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async disableMultiple(
    context: BrandActionContext,
    brands: Brand[]
  ): Promise<any> {
    const brandIds = brands.map((brand: Brand) => brand.id!);
    context.commit("setLoading", true);
    try {
      await brandService.disableMultiple(brandIds);
      messagesService.renderSuccessMessage("updated_successfully");
      context.dispatch("loadBrands");
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async destroy(context: BrandActionContext) {
    context.commit("setBrand", []);
    context.commit("setProducts", []);
    context.commit("setBatches", []);
    context.commit("setProductsPagination", null);
    context.commit("setBatchesPagination", null);
  },

  async loadBrand(context: BrandActionContext, brandId: string): Promise<any> {
    try {
      const brand = await brandService.getBrand(brandId);
      brand.brand_vendors = brand.vendors.map((vendor: Vendor) => ({
        vendor_id: vendor.id
      }));
      context.commit("setBrand", brand);
    } catch (error) {
      store.dispatch("RouterModule/errorRedirect", {
        location: { name: "brands" },
        error
      });
    }
  },

  async loadProducts(
    context: BrandActionContext,
    brandId: string
  ): Promise<any> {
    try {
      context.commit("setLoading", true);
      const products = await brandSubTableService.getProductsAssigned(brandId);
      const pagination: TablePagination = await brandSubTableService.getPagination(
        true
      );
      context.commit("setProducts", products);
      context.commit("setProductsPagination", pagination);
      context.commit("setLoading", false);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },

  async loadBatches(
    context: BrandActionContext,
    data: { id: number; zeroOnly?: boolean }
  ): Promise<any> {
    try {
      context.commit("setLoading", true);
      const batches: InventoryBatch[] = await brandSubTableService.getBatchesAssigned(
        (data && data.id) || context.getters.brand.id,
        data && data.zeroOnly
      );
      batches.forEach((batch: InventoryBatch) => {
        batch.remaining_weight = `${+batch.in_store_quantity_value!.toFixed(
          2
        )} ${batch.in_store_quantity_unit!}`;
        return batch;
      });
      const pagination: TablePagination = await brandSubTableService.getPagination(
        true
      );
      context.commit("setBatches", batches);
      context.commit("setBatchesPagination", pagination);
      context.commit("setLoading", false);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }
};
