import { Batch } from "@/interfaces/batch";
import { Brand } from "@/interfaces/brand";
import { Product } from "@/interfaces/product";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { GetterTree } from "vuex";
import { BrandState } from "./brand.type";

type BrandGetter = GetterTree<BrandState, RootState>;

export const getters: BrandGetter = {
  brands(state: BrandState): Brand[] | null {
    return state.brands;
  },
  pagination(state: BrandState): TablePagination | null {
    return state.pagination;
  },
  loading(state: BrandState): boolean {
    return state.loading;
  },
  brand(state: BrandState): Brand | null {
    return state.brand;
  },
  products(state: BrandState): Product[] | null {
    return state.products;
  },
  batches(state: BrandState): Batch[] | null {
    return state.batches;
  },
  productsPagination(state: BrandState): TablePagination | null {
    return state.productsPagination;
  },
  batchesPagination(state: BrandState): TablePagination | null {
    return state.batchesPagination;
  }
};
