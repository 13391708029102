import { BrandState } from "./brand.type";

export const state: BrandState = {
  brands: [],
  pagination: null,
  loading: false,
  brand: {
    name: "",
    brand_vendors: [],
    logo_url: null
  },
  products: [],
  batches: [],
  productsPagination: null,
  batchesPagination: null
};
