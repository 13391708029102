import { BrandModule } from "@/vuex/modules/inventory/brand/brand.index";
import { Component, Vue } from "vue-property-decorator";

@Component({
  render: c => c("router-view")
})
export default class BrandsComponent extends Vue {
  protected created() {
    this.$store.registerModule("BrandModule", BrandModule);
  }

  protected beforeDestroy() {
    this.$store.unregisterModule("BrandModule");
  }
}
